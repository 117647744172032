import React from "react"
import Layout from "../../../components/sermonLayoutElMirage"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/Vvb0ZZI-S9E">
    <SEO title="Jesus is Greater - Hebrews" />
  </Layout>
)

export default SermonPost
